@import '~react-quill/dist/quill.snow.css';

.ql-container {
    font-family: sans-serif;
    font-size: 16px;
}

.ql-editor {
    color: #212121;

    &.ql-blank:before {
        left: unset;
        right: unset;
    }
}

.ql-snow {
    .ql-tooltip {
        border-color: #d7d7d7;
        box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
        border-radius: 4px;

        &.ql-editing a.ql-action::after {
            color: #212121;
        }

        input[type='text'] {
            border-color: #e0e6ed;
            outline-color: #e0e6ed;
            border-radius: 4px;
        }
    }
}
