.EmailTemplateForm {
    .disabledInput {
        input {
            color: var(--color-black);
            -webkit-text-fill-color: var(--color-black);
        }
    }

    .TextHelper {
        vertical-align: middle;
    }
}
