.image-uploading {
    position: relative;
    display: inline-block;
}

.image-uploading img {
    max-width: 98%;
    filter: blur(1px);
    opacity: 0.5;
}

.image-uploading::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: var(--company-primary-color);
    z-index: 1;
    animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}
