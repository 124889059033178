.quillEditor {
    // .ql-container
    > pre {
        border-radius: 8px;
        border-color: #e0e6ed;
        margin-top: 16px;

        // .ql-editor
        & > div:first-child {
            padding: 56px 40px;
            min-height: 50vh;
            max-height: calc(100vh - 375px);
            overflow-y: auto;
        }
    }
}
