.selectControl {
    fieldset {
        border-color: transparent !important;
    }
}

.selectIcon {
    svg {
        vertical-align: middle;
    }
}
