.preview {
    p {
        font-family: sans-serif;
    }
}

.mobile {
    max-width: 480px;
}

.desktop {
    max-width: 1280px;
}
