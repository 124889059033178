// 1920x1080
// 1vh - 10.8
// 1vw - 19.2

// 1440x900
// 1vh - 9
// 1vw - 14.4

[data-rmiz-btn-unzoom] {
    display: none;
}

.svg-icon {
    color: transparent;

    path {
        fill: var(--company-primary-color);
    }
}
